import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import "@arctravel/react-fds/lib/index.scss";
import "@arctravel/react-fds/lib/glyphs.scss";
import "@arctravel/react-fds/lib/fonts.scss";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);