import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";


function HostedSessionButton() {
  const history = useHistory();
  function handleClick() {

      history.push(`/session`);
  }

  return <Button variant="primary" onClick={handleClick}>Launch Hosted Session</Button>

}

export default HostedSessionButton;