import { combineReducers, createStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import featuresReducer from './features'
import paymentsReducer from './payments'
import storageSession from 'redux-persist/lib/storage/session'


const rootReducer = combineReducers({
  features: featuresReducer,
  payments: paymentsReducer
});


const persistConfig = {
  key: 'root',
  storage: storageSession,
}
 

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer);

export const persistor = persistStore(store);


export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch