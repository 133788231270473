import { Modal } from "react-bootstrap";
import { IErrorModalProps } from "./error-modal-props";



function ErrorModal({ showErrorModal, setShowErrorModal, message}: IErrorModalProps)
{

   return  <Modal className="fade error modal" show={showErrorModal} onHide={() => setShowErrorModal(false)}>
    <Modal.Header closeButton className="error-modal-header">
        <Modal.Title className="header error-modal-title">
            <i className="fds-glyphs-warning2"></i>
            System Error
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {message == null ? "A problem occurred. Please try again." : message }
    </Modal.Body>
</Modal>

}

export default ErrorModal;