

function RedirectComponent ()
{

    const referrer = document.referrer.toString();

    const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

    const apiUrl = `${process.env.REACT_APP_ARCPAY_API_URL}`;

    const apiTestUrl = `${process.env.REACT_APP_ARCPAY_API_TEST_URL}`;



    if (!referrer.includes(baseUrl) && !referrer.startsWith(apiUrl) && !referrer.startsWith(apiTestUrl))
    {
        window.location.replace(baseUrl);
    }

    return <div></div>;
}

export default RedirectComponent;