import { useEffect, useState } from "react"
import { Row, Col, Spinner } from "react-bootstrap"
import *  as FDS from "@arctravel/react-fds/lib"
import { useHistory } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import './pay.css';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { updateState } from "../../redux/payments";
import { getMgpsApiHelperParams } from "../../helpers/mpgs-api-helper";
import { buildQueryStringFromFeatureState } from "../../helpers/query-params-helper";
import TestOrProd from "../home/TestOrProd";

const axios = require('axios').default;


function Pay() {
    const history = useHistory();
    const featureState = useAppSelector(state => state.features);
    const paymentState = useAppSelector(state => state.payments);
    const dispatch = useAppDispatch();

    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken;
    const groups = token?.claims.groups as string[];
    const isAdmin = groups && groups.some((g:string) => g === process.env.REACT_APP_ADMIN_ROLE);
    const {path} = getMgpsApiHelperParams(isAdmin, paymentState);
    
    const session = paymentState.sessionId;

    const [needsPayment, setNeedsPayment] = useState(paymentState.needsPayment !== undefined ? true : false);
    const [needsAuth, setNeedsAuth] = useState(paymentState.needsAuth !== undefined ? true : false);
    const paramsString = buildQueryStringFromFeatureState(featureState);

    useEffect(() => {
        if (session === undefined || paymentState.orderId === undefined) {
            history.push("/");
            return;
        }

        const auth = async () => {
            if (needsAuth) {

                const transactionId = uuidv4();
                const token = oktaAuth.getAccessToken();
                const initAuth = await axios.put(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/initiate-authentication`, { sessionId: session, orderId: paymentState.orderId, transactionId: transactionId },
                    {
                        headers: {
                            Authorization: "Bearer " + token,
                        }
                    });

   
                if (initAuth.data?.error?.explanation) {
                    let errorMessage = initAuth.data?.error?.explanation; 

                    if (initAuth.data?.error?.supportCode)
                    {
                        errorMessage += ` Support code: ${initAuth.data?.error?.supportCode}.`;
                    }

                    dispatch(updateState({ field: "paymentErrorMessage", value: errorMessage}));
                    dispatch(updateState({ field: "needsPayment", value: undefined }));
                    dispatch(updateState({ field: "needsAuth", value: undefined }));
                    history.push(`/confirmation/${paymentState.orderId}${paramsString}`);
                }


                const body = {
                    sessionId: session, orderId: paymentState.orderId, transactionId: transactionId,
                    browser: navigator.userAgent,
                    redirectUrl: `${process.env.REACT_APP_MPGS_BASE_URL}${path}/pay-response`,
                    browserDetails: {
                        screenHeight: window.screen.height,
                        screenWidth: window.screen.width,
                        language: navigator.language,
                        colorDepth: window.screen.colorDepth,
                        javaEnabled: navigator.javaEnabled(),
                        timeZone: new Date().getTimezoneOffset(),
                        acceptHeaders: 'application/json',
                        '3DSecureChallengeWindowSize': 'FULL_SCREEN',
                    },
                };

                if (initAuth.data?.transaction?.authenticationStatus === "AUTHENTICATION_AVAILABLE" && !paymentState.paymentErrorMessage) {
                    const oktaToken = oktaAuth.getAccessToken();
                    const authUpdate = await axios.put(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/authenticate-payer`, body, {
                        headers: {
                            Authorization: "Bearer " + oktaToken,
                        }
                    });

                    if (authUpdate.data?.error?.explanation) {
                        
                        console.log("went to error");
                        let errorMessage = authUpdate.data?.error?.explanation; 

                        if (authUpdate.data?.error?.supportCode)
                        {
                            errorMessage += ` Support code: ${authUpdate.data?.error?.supportCode}.`;
                        }
    
                        dispatch(updateState({ field: "paymentErrorMessage", value: errorMessage}));
                        dispatch(updateState({ field: "needsPayment", value: undefined }));
                        dispatch(updateState({ field: "needsAuth", value: undefined }));
                        history.push(`/confirmation/${paymentState.orderId}${paramsString}`);
                    }
                    else {
                        setNeedsAuth(false);
                        dispatch(updateState({ field: "transactionId", value: transactionId}));;
                    }

                    if (authUpdate.data?.authentication?.payerInteraction === "NOT_REQUIRED") {
                        setNeedsPayment(true);
                        dispatch(updateState({ field: "needsPayment", value: "true" }));
                        dispatch(updateState({ field: "needsAuth", value: undefined }));
                    }
                    else if (authUpdate.data?.authentication?.payerInteraction === "REQUIRED") {
                        dispatch(updateState({ field: "redirectHtml", value: authUpdate.data.authentication.redirectHtml }));
                        history.push("/threeds-challenge");
                    }
                    else if (!paymentState.paymentErrorMessage) {
                        if (authUpdate.data?.response?.gatewayCode === "APPROVED") {
                            setNeedsPayment(true);
                            dispatch(updateState({ field: "needsPayment", value: "true" }));
                            dispatch(updateState({ field: "needsAuth", value: undefined }));
                        }
                        else {
                            dispatch(updateState({ field: "needsPayment", value: undefined }));
                            dispatch(updateState({ field: "needsAuth", value: undefined }));
                            history.push(`/confirmation/${paymentState.orderId}${paramsString}`);
                        }
                    }
                }
                else {
                    if (initAuth.data?.response?.gatewayCode === "APPROVED") {
                        setNeedsAuth(false);
                        setNeedsPayment(true);
                        dispatch(updateState({ field: "needsPayment", value: "true" }));
                        dispatch(updateState({ field: "needsAuth", value: undefined }));
                    }
                    else {
                        dispatch(updateState({ field: "needsPayment", value: undefined }));
                        dispatch(updateState({ field: "needsAuth", value: undefined }));
                        history.push(`/confirmation/${paymentState.orderId}${paramsString}`);
                    }
                }
            }
        }
        auth();
    }, [oktaAuth, dispatch, history, needsAuth, paymentState.orderId, session, paramsString, path, paymentState,  featureState]);


    useEffect(() => {
        if (session === undefined || paymentState.orderId === undefined) {
            history.push("/");
            return;
        }

        const pay = async () => {

            if (!needsAuth && needsPayment) {
   
                const body = {
                    sessionId: session, orderId: paymentState.orderId
                } as any;

                if (featureState.include3DS)
                {
                    body.authTransactionId = paymentState.transactionId                }

                const token = oktaAuth.getAccessToken();
                axios.put(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/pay`, body,
                    {
                        headers: {
                            Authorization: "Bearer " + token,
                        }
                    }
                ).then((payUpdate: any) => {

                    if (payUpdate.data?.error?.explanation) {
                        dispatch(updateState({ field: "paymentErrorMessage", value: payUpdate.data?.error?.explanation }));
                    }
                    dispatch(updateState({ field: "needsPayment", value: undefined }));

                    let confirmationUrl = `/confirmation/${paymentState.orderId}${paramsString}`;


                    history.push(confirmationUrl);
                });
            }
        }

        pay();
    }, [oktaAuth, dispatch, history, needsAuth, needsPayment, paymentState.orderId, paymentState.sessionId, paymentState.transactionId, featureState.include3DS, paramsString, session, path]);

    return <FDS.FDSLevel mainTitle="ARC Pay API Demo" type="two" subTitle="Session Checkout">

        <FDS.FDSLevel.Centered>
            <TestOrProd />
            <Row>
                <Col lg="12">
                    <div>
                        <div style={{ height: "500px", width: "100%", lineHeight: "350px", textAlign: "center" }}>
                            <div style={{ width: "100%", height: "100px", lineHeight: "initial", verticalAlign: "middle", display: "inline-block" }}>
                                <div style={{ paddingBottom: "10px" }} className="progress-message">
                                    Processing Payment...
                                </div>
                                <Spinner animation="border" style={{ fontSize: "16px" }} role="status" variant="primary">
                                </Spinner>

                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </FDS.FDSLevel.Centered>
        <div></div>
    </FDS.FDSLevel>
}

export default Pay
