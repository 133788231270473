import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function HostedFullRedirectButton()
{
    const history = useHistory();

    function handleClick() {
      history.push("/checkout-redirect");
    }

    return  <Button variant="primary" onClick={handleClick}>Launch Hosted Checkout (Full Redirect)</Button>

}

export default HostedFullRedirectButton;