import { Form } from "react-bootstrap";
import { INCLUDE_WALLET_PAYMENTS, updateState } from "../../redux/features";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

function IncludeWalletPaymentsCheckbox (){

    const dispatch = useAppDispatch();
    const featureState = useAppSelector(state => state.features);

    function handleClick() { 
          dispatch(updateState({feature: INCLUDE_WALLET_PAYMENTS, value: featureState.includeWalletPayments ? false : true }));
      }

    return <Form.Check type="checkbox" id="wallet" label="Include Wallet Payments" checked={featureState.includeWalletPayments} onChange={handleClick}/>

}

export default IncludeWalletPaymentsCheckbox;