import { useCallback, useEffect } from 'react';
import { UseApplePayConfigs } from '../components/applepay/types';


export const useApplePay = ({
  paymentRequest,
  onValidateMerchant,
  onPaymentAuthorized,
  onCancel,
  onPaymentMethodSelected,
  values,
  validate,
  errors,
  dependencies = [],
}: UseApplePayConfigs) => {
  const onRequestApplePay: VoidFunction = useCallback(() => 
  {
    if (!(window as any).ApplePaySession || !ApplePaySession.canMakePayments()) 
    {
        return;
    }

    try {

      const session = new ApplePaySession(6, paymentRequest);
      session.onvalidatemerchant = event => onValidateMerchant(event, session);
      session.onpaymentauthorized = event => onPaymentAuthorized(event, session);
      session.oncancel = event => onCancel(event, session);
      session.onpaymentmethodselected = event => onPaymentMethodSelected(event, session);
      session.begin();
      
    }
    catch (ex)
    {
        console.log(ex);
    }
  }, [paymentRequest, onValidateMerchant, onPaymentAuthorized, onCancel, onPaymentMethodSelected]);

  useEffect(() => {
    document
      .querySelector('apple-pay-button')
      ?.addEventListener('click', onRequestApplePay);
    return () => {
      document
        .querySelector('apple-pay-button')
        ?.removeEventListener('click', onRequestApplePay);
    };
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRequestApplePay, ...dependencies]);

  return { applePayClickHandler: onRequestApplePay };
};