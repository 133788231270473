import { useEffect } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { Row, Col, Spinner } from "react-bootstrap";
import *  as FDS from "@arctravel/react-fds/lib"
import { updateState } from "../../redux/payments";
import { getMgpsApiHelperParams } from "../../helpers/mpgs-api-helper";
import { buildQueryStringFromFeatureState } from "../../helpers/query-params-helper";
import { INCLUDE_3DS, INCLUDE_AVS_AND_CVV_RESPONSES, INCLUDE_TOKENIZATION, INCLUDE_WALLET_PAYMENTS, updateState as updateFeatureState } from "../../redux/features";
import TestOrProd from "../home/TestOrProd";
const axios = require('axios').default;
function PayResponse()
{
    const history = useHistory();
    const dispatch = useAppDispatch();

    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken;
    const groups = token?.claims.groups as string[];
    const isAdmin = groups && groups.some((g:string) => g === process.env.REACT_APP_ADMIN_ROLE);
  
    const featureState = useAppSelector(state => state.features);
    const paymentState = useAppSelector(state => state.payments);
    const {path} = getMgpsApiHelperParams(isAdmin, paymentState);


    const paramsString = buildQueryStringFromFeatureState(featureState);

    useEffect(() => {
        document.querySelector("iframe")?.remove();
        document.querySelector("#threedcontainer")?.remove();
        document.querySelector("#threedsChallengeRedirect")?.remove();
        document.querySelector("script")?.remove();

        if (!paymentState?.orderId)
        {
            history.push("/");
        }

  

        const token = oktaAuth.getAccessToken();
    
            axios.get(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/get-order/${paymentState.orderId}`,
            {
                headers: {
                   Authorization: "Bearer " + token,
                 } }
            ).then((orderResp: any) => {
    
                dispatch(updateState({ field: "orderId", value: paymentState.orderId }));
                dispatch(updateFeatureState({feature: INCLUDE_3DS, value: featureState.include3DS }));
                dispatch(updateFeatureState({feature: INCLUDE_AVS_AND_CVV_RESPONSES, value: featureState.includeAVSandCVVResponses }));
                dispatch(updateFeatureState({feature: INCLUDE_TOKENIZATION, value: featureState.includeTokenization  }));
                dispatch(updateFeatureState({feature: INCLUDE_WALLET_PAYMENTS, value: featureState.includeWalletPayments }));
                

                if (orderResp.data?.transaction[0]?.transaction?.authenticationStatus === "AUTHENTICATION_SUCCESSFUL")
                {
                    dispatch(updateState({ field: "needsPayment", value: "true" }));
                    dispatch(updateState({ field: "needsAuth", value: undefined }));
                    dispatch(updateState({ field: "transactionId", value: paymentState.transactionId }));
                    history.push(`/pay`);
                }
                else {
                    dispatch(updateState({ field: "needsPayment", value: undefined }));
                    dispatch(updateState({ field: "needsAuth", value: undefined }));
                    history.push(`/confirmation/${paymentState.orderId}${paramsString}`);
                }
            });
    }, [oktaAuth, dispatch, history, paymentState.orderId, paramsString, path, paymentState, featureState.include3DS, featureState.includeAVSandCVVResponses, featureState.includeTokenization, featureState.includeWalletPayments]);

return  <FDS.FDSLevel mainTitle="ARC Pay API Demo" type="two" subTitle="Session Checkout">

<FDS.FDSLevel.Centered>
    <TestOrProd />
    <Row>
        <Col lg="12">
            <div>
                <div style={{ height: "500px", width: "100%", lineHeight: "350px", textAlign: "center" }}>
                    <div style={{ width: "100%", height: "100px", lineHeight: "initial", verticalAlign: "middle", display: "inline-block" }}>
                        <div style={{ paddingBottom: "10px" }} className="progress-message">
                            Processing Payment...
                        </div>
                        <Spinner animation="border" style={{ fontSize: "16px" }} role="status" variant="primary">
                        </Spinner>

                    </div>
                </div>
            </div>
        </Col>
    </Row>
</FDS.FDSLevel.Centered>
<div></div>
</FDS.FDSLevel>
}

export default PayResponse;