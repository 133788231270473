import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap"
import ApplePay from "../applepay/ApplePay";
import { InputState, ErrorState } from "./FormState";

interface IHostedSessionFormProps {

    initializing: boolean;
    values: InputState;
    handleChange: any;
    formatCurrency: any;
    errors: ErrorState;
    pay: any;
    setMiddleInitial: any;
    sessionId: any;
    validate: any;
}

function HostedSessionForm({ validate, initializing, values, errors, handleChange, pay, setMiddleInitial, formatCurrency, sessionId }: IHostedSessionFormProps) {

    const [amount, setAmount] = useState("");
    
    const setOrderAmount = () => {
        setAmount(values.orderAmount?.replace("$", "") || "0.00")
        formatCurrency();
    }
    const states = ["AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY"];

    const years: any = [];
    const currentYear = new Date().getFullYear()

    let year = currentYear;
    years.push(currentYear.toString().substr(2, 2));

    const target = 2039 - currentYear;
    for (let i = 0; i < target; i++) {
        year++;
        years.push(year.toString().substr(2, 2))
    }

    return <Form id="hostedSessionForm" style={{ display: initializing ? "none" : undefined }}>
        <br />
        <br />
        <h3>Please Enter Your Information</h3>
        <br />
        <br />
        <span className="sub-group-label">Payment Information</span>
        <br />
        <br />
        <Row>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="orderAmount">Transaction Amount </Form.Label>

                    <Form.Control autoComplete="off" type="text" placeholder="$0.00" value={values.orderAmount} onChange={handleChange} onBlur={setOrderAmount} isInvalid={errors.orderAmount ? true : false} id="orderAmount"  aria-label="enter your order amount" />
                    <div style={{ height: "7px" }}></div>
                    <Form.Control.Feedback type="invalid">{errors.orderAmount}</Form.Control.Feedback>

                </Form.Group>
            </Col>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="transactionCurrency">Transaction Currency</Form.Label>
                    <div className="select">
                        <Form.Control autoComplete="off" disabled style={{ backgroundColor: "white", color: "black" }} as="select" id="transactionCurrency" className="shadow-none"  aria-label="currency type">
                            <option>USD</option>
                        </Form.Control>
                    </div>
                </Form.Group>
            </Col>
        </Row>
      
         <ApplePay  amount={amount} errors={errors} values={values} validate={validate} />
        
   
        <Row>
            <Col lg="12">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="cardholder-name">Cardholder Name </Form.Label>
                    <Form.Control autoComplete="off" type="text" minLength={1} required id="cardholder-name" aria-label="enter name on card" readOnly />
                    <Form.Control.Feedback type="invalid">Cardholder Name is required.</Form.Control.Feedback>
                </Form.Group>

            </Col>
        </Row>
        <Row>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="card-number">Card Number </Form.Label>
                    <Form.Control autoComplete="off" type="text" id="card-number" aria-label="enter your card number" readOnly />
                    <Form.Control.Feedback type="invalid">Must enter a valid Card Number.</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="creditCardType">Credit Card Type </Form.Label>
                    <div className="select">
                        <Form.Control autoComplete="off" as="select" id="creditCardType" value={values.creditCardType} className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.creditCardType ? true : false)}  aria-label="enter credit card type">
                            <option value="">Select a Credit Card Type</option>
                            <option value="Visa">Visa</option>
                            <option value="Mastercard">Mastercard</option>
                            <option value="American Express">American Express</option>
                        </Form.Control>
                        <div style={{ height: "7px" }}></div>
                        <Form.Control.Feedback id="invalid-feedback" type="invalid">Credit Card Type is required.</Form.Control.Feedback>
                    </div>

                </Form.Group>
            </Col>
        </Row>


        <Row>
            <Col lg="4">
                <Form.Group className="mb-3 wrapper">
                    <Form.Label htmlFor="expiry-month">Exp. Month </Form.Label>
                    <div className="select">
                        <Form.Control autoComplete="off" as="select" id="expiry-month"
         aria-label="two digit expiry month" readOnly required>
                            <option value="">Select Month</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Exp. Month is required.</Form.Control.Feedback>
                    </div>

                </Form.Group>
            </Col>
            <Col lg="4">
                <Form.Group className="mb-3 wrapper">
                    <Form.Label htmlFor="expiry-year">Exp. Year </Form.Label>
                    <div className="select">
                        <Form.Control autoComplete="off" as="select" id="expiry-year" aria-label="two digit expiry year" readOnly required>
                            <option value="">Select Year</option>
                            {years.map((year: any) => <option key={year} value={year} >{year}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Exp. Year is required.</Form.Control.Feedback>
                    </div>

                </Form.Group>
            </Col>
            <Col lg="4">
                <Form.Group className="mb-3 wrapper">
                    <Form.Label htmlFor="security-code">CVV / CID </Form.Label>
                        <Form.Control required autoComplete="off" type="text" id="security-code" aria-label="three digit CCV security code" readOnly />
                    <Form.Control.Feedback type="invalid">Must enter a valid Security Code.</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <div style={{ borderBottom: "1px solid rgba(232,232,232, 1)" }}></div>
            </Col>
        </Row>
        <br />
        <span className="sub-group-label">Contact Information</span>
        <br />
        <br />
        <Row>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="address">Address </Form.Label>
                    <Form.Control type="text" autoComplete="off" id="address" value={values.address} className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.address ? true : false)} aria-label="street address" />
                    <div style={{ height: "7px" }}></div>
                    <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="unit">Unit </Form.Label>
                    <Form.Control type="text" autoComplete="off" id="unit" value={values.unit} className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.unit ? true : false)} aria-label="unit" />
                    <div style={{ height: "8px" }}></div>
                    <span className="optional-label">Optional</span><Form.Control.Feedback type="invalid">Must enter a valid Unit.</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col lg="5">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="city">City </Form.Label>
                    <Form.Control autoComplete="off" type="text" id="city" value={values.city} className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.city ? true : false)} aria-label="city" />
                    <div style={{ height: "7px" }}></div>
                    <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col lg="4">
                <Form.Group className="mb-3">

                    <Form.Label htmlFor="state">State </Form.Label>
                    <div className="select">
                        <Form.Control autoComplete="off" as="select" id="state" value={values.state} className="shadow-none" style={{ background: "none" }} onChange={handleChange} onBlur={handleChange} isInvalid={(errors.state ? true : false)} aria-label="enter your state">
                            <option value="">Select a State</option>
                            {states.map((state: any) => <option key={state} value={state} >{state}</option>)}
                        </Form.Control>
                        <div style={{ height: "7px" }}></div>
                        <Form.Control.Feedback type="invalid">State is required.</Form.Control.Feedback>
                    </div>

                </Form.Group>
            </Col>
            <Col lg="3">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="zip">Zip Code</Form.Label>
                    <Form.Control autoComplete="off" type="text" id="zip" value={values.zip} className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.zip ? true : false)} aria-label="zip" />
                    <div style={{ height: "7px" }}></div>
                    <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <div style={{ borderBottom: "1px solid rgba(232,232,232, 1)" }}></div>
            </Col>
        </Row>
        <br />
        <span className="sub-group-label">Passenger Information</span>
        <br />
        <br />
        <Row>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="namePrefix">Name Prefix </Form.Label>
                    <div className="select">
                        <Form.Control autoComplete="off" as="select" id="namePrefix" value={values.namePrefix} className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.namePrefix ? true : false)} aria-label="enter your name prefix">
                            <option value="">Select a Name Prefix</option>
                            <option>Mr.</option>
                            <option>Mrs.</option>
                        </Form.Control>
                        <div style={{ height: "7px" }}></div>
                        <Form.Control.Feedback type="invalid">Name Prefix is required.</Form.Control.Feedback>
                    </div>

                </Form.Group>
            </Col>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="firstName">First Name</Form.Label>
                    <Form.Control autoComplete="off" type="text" id="firstName" value={values.firstName} className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.firstName ? true : false)}  aria-label="enter first name" />
                    <div style={{ height: "7px" }}></div>
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="middleName">Middle Name/Initial</Form.Label>
                    <Form.Control autoComplete="off" type="text" id="middleName" value={values.middleName} onBlur={setMiddleInitial} className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.middleName ? true : false)} aria-label="enter middle name" />
                    <div style={{ height: "8px" }}></div>
                    <span className="optional-label">Optional</span>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>

                </Form.Group>

            </Col>
            <Col lg="6">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="lastName">Last Name</Form.Label>
                    <Form.Control autoComplete="off" type="text" value={values.lastName} id="lastName" className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.lastName ? true : false)} aria-label="enter last name" />
                    <div style={{ height: "7px" }}></div>
                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="associatedAirlineTicket">Associated Airline Ticket</Form.Label>
                    <Form.Control autoComplete="off" type="text" value={values.associatedAirlineTicket} id="associatedAirlineTicket" className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.associatedAirlineTicket ? true : false)} aria-label="enter airline ticket" />
                    <div style={{ height: "8px" }}></div>
                    <span className="optional-label">Optional</span>
                    <Form.Control.Feedback type="invalid">{errors.associatedAirlineTicket}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="agencyInvoice">Agency Invoice Number</Form.Label>
                    <Form.Control autoComplete="off" type="text" value={values.agencyInvoice} id="agencyInvoice" className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.agencyInvoice ? true : false)}  aria-label="enter agency invoice" />
                    <div style={{ height: "8px" }}></div>
                    <span className="optional-label">Optional</span>
                    <Form.Control.Feedback type="invalid">{errors.agencyInvoice}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="pnr">PNR / Airline Booking Reference</Form.Label>
                    <Form.Control autoComplete="off" type="text" id="pnr" value={values.pnr}  className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.pnr ? true : false)} aria-label="enter pnr" />
                    <div style={{ height: "8px" }}></div>
                    <span className="optional-label">Optional</span>
                    <Form.Control.Feedback type="invalid">{errors.pnr}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="chargeDescription">Charge Description</Form.Label>
                    <Form.Control autoComplete="off" as="textarea" rows={4} id="chargeDescription" className="shadow-none" style={{ background: "none" }} onChange={handleChange} isInvalid={(errors.chargeDescription ? true : false)}  aria-label="enter charge description" />
                    <div style={{ height: "8px" }}></div>
                    <span className="optional-label">Optional</span>
                    <Form.Control.Feedback type="invalid">{errors.chargeDescription}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <br />
        <Row>
            <Col lg="2">
            </Col>
            <Col lg="6" style={{ paddingRight: window.innerWidth > 768 ? "0px" : "1rem", paddingBottom: "20px", textAlign: "right" }}>

                <span className="total-label">
                    Total Payment: {amount ? values.orderAmount : "$0.00"}
                </span>
            </Col>
            <Col lg="4" style={{ paddingRight: "1rem", paddingBottom: "20px", textAlign: "right" }}>
                <div style={{ textAlign: "right", width: "100%" }}>
                    <Button variant="primary" onClick={pay}>
                        Submit Payment
                    </Button>
                </div>
            </Col>
        </Row>
    </Form>
}

export default HostedSessionForm