import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function HostedCheckoutEmbedButton()
{
    const history = useHistory();

    function handleClick() {
      history.push("/checkout");
    }

    return  <Button variant="primary"  onClick={handleClick}>Launch Hosted Checkout</Button>

}

export default HostedCheckoutEmbedButton;