import { Form } from "react-bootstrap";
import { INCLUDE_3DS, updateState } from "../../redux/features";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

function Include3DSCheckbox (){

    const dispatch = useAppDispatch();
    const featureState = useAppSelector(state => state.features);

    function handleClick() { 
        dispatch(updateState({feature: INCLUDE_3DS, value: featureState.include3DS ? false : true }));
  
      }

    return <Form.Check type="checkbox" id="3ds"  label="Include 3DS" checked={featureState.include3DS} onChange={handleClick}/>

}

export default Include3DSCheckbox;