import React, { FC, createElement } from 'react';
import { ApplePayButtonProps } from './types';
import { useScript } from '../../hooks/useScript';

export const ApplePayButton: FC<ApplePayButtonProps> = props => {
  useScript('https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js');

  return (
    <div>
      {createElement<ApplePayButtonProps, HTMLElement>('apple-pay-button', {
        buttonstyle: props.buttonstyle || 'black',
        type: props.type || 'plain',
        locale: props.locale || 'en-US',
      })}
    </div>
  );
};
