import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const OktaSignInWidget = ({ config, onSuccess, onError }: any) => {

  const widgetRef =  useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!widgetRef.current)
      return;

    const widget = new OktaSignIn(config);

   widget.showSignInToGetTokens({
      el: widgetRef.current as any,
    }).then(onSuccess).catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);


  return (<div ref={widgetRef} />);
};
export default OktaSignInWidget;