import { Col, Form, Row } from "react-bootstrap";
import HostedCheckoutEmbedButton from "./HostedCheckoutEmbedButton";
import HostedFullRedirectButton from "./HostedFullRedirectButton";
import CreatePaymentLinkButton from "./CreatePaymentLinkButton";
import HostedSessionButton from "./HostedSessionButton";
import Include3DSCheckbox from "./Include3DSCheckbox";
import IncludeTokenizationCheckbox from "./IncludeTokenizationCheckbox";
import IncludeWalletPaymentsCheckbox from "./IncludeWalletPaymentsCheckbox";
import IncludeAddressValidationCheckbox from "./IncludeAddressValidationCheckbox";
import *  as FDS from "@arctravel/react-fds/lib"
import TestOrProd from "./TestOrProd";
import { INCLUDE_3DS, INCLUDE_AVS_AND_CVV_RESPONSES, INCLUDE_TOKENIZATION, INCLUDE_WALLET_PAYMENTS, updateState } from "../../redux/features";
import {updateState as updatePayState } from "../../redux/payments";
import { useAppDispatch } from "../../redux/hooks";
import { useEffect } from "react";


function HomePage() {

    const MASTER_CARD_CHECKOUT_JS_SRC = `.arcpay.travel/static/checkout/checkout.js`;
    const prevScript = document.querySelector(
        `script[src$="${MASTER_CARD_CHECKOUT_JS_SRC}"]`
    );
    if (prevScript) {
        prevScript.remove();
    }

    sessionStorage.clear();

    if ((window as any).Checkout)
    {
        
        delete (window as any).Checkout;
        delete (window as any).$localize;
        (window as any).iFrameResize = undefined;
        (window as any).onload = undefined;
        (window as any).completeCallback = undefined;
        (window as any).cancelCallback = undefined;
        (window as any).frames = undefined;

        window.location.reload();
    }
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(updatePayState({ field: "environment", value: "test" }));
        dispatch(updatePayState({ field: "sessionId", value: undefined }));
        dispatch(updatePayState({ field: "transactionId", value: undefined }));
        dispatch(updatePayState({ field: "orderId", value: undefined }));
        dispatch(updatePayState({ field: "redirectHtml", value: undefined }));
        dispatch(updatePayState({ field: "needsAuth", value: undefined }));
        dispatch(updatePayState({ field: "needsPayment", value: undefined }));
        dispatch(updatePayState({ field: "paymentErrorMessage", value: undefined }));
        dispatch(updateState({feature: INCLUDE_AVS_AND_CVV_RESPONSES, value: false }));
        dispatch(updateState({feature: INCLUDE_TOKENIZATION, value: false }));
        dispatch(updateState({feature: INCLUDE_3DS, value: false }));
        dispatch(updateState({feature: INCLUDE_WALLET_PAYMENTS, value: false }));
    }, [dispatch]);



 
    
    return (<FDS.FDSLevel mainTitle="ARC Pay API Demo" type="one">
        <FDS.FDSLevel.Centered>
                    <TestOrProd />
                    <Row>
                    <Col md={2} lg={3}></Col>        
                    <Col md={4} lg={3}>   
                        <h2 style={{lineHeight: "32px", paddingTop:"50px"}}>Select an Integration</h2>
                        </Col>
                        <Col md="3">
                        </Col>
                        <Col md="3">
                        </Col>
                    </Row>        
                    <Row>
                    <Col md={2} lg={3}></Col>        
                    <Col md={4} lg={3}>                           
                            <div style={{fontSize: "16px", paddingBottom:"50px"}}>
                            <span style={{lineHeight: "16px"}}>Include Features:</span>
                                <Form>
                                    <Form.Group style={{lineHeight: "24px", paddingTop:"10px"}}>
                                        <Include3DSCheckbox />
                                    </Form.Group>
                                    <Form.Group style={{lineHeight: "24px", paddingTop:"10px"}}>
                                        <IncludeTokenizationCheckbox />
                                    </Form.Group>
                                    <Form.Group style={{lineHeight: "24px", paddingTop:"10px"}}>
                                        <IncludeWalletPaymentsCheckbox />
                                    </Form.Group>
                                    <Form.Group style={{lineHeight: "24px", paddingTop:"10px"}}>
                                        <IncludeAddressValidationCheckbox />
                                    </Form.Group>
                                </Form>
                            </div>
                        </Col>
                        <Col md="auto" lg="6" style={{ textAlign: "left" }}>

                            <div style={{ justifyContent: "left", width: "100%" }}>

                                <div>
                                    <div style={{ paddingBottom: "30px" }}>
                                        <HostedFullRedirectButton />
                                    </div>
                                    <div style={{ paddingBottom: "30px" }}>
                                        <HostedCheckoutEmbedButton />
                                    </div>
                                    <div style={{ paddingBottom: "30px" }}>
                                        <HostedSessionButton />
                                    </div>
                                    <div style={{ paddingBottom: "30px" }}>
                                        <CreatePaymentLinkButton />
                                    </div>
                                    <br /><br />
                                    <div className="token-code-block hide-button mt-3"></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
        </FDS.FDSLevel.Centered>
        <div></div>
    </FDS.FDSLevel>)
}

export default HomePage;
