import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Row, Col, Form, Spinner, Alert, Container } from "react-bootstrap"
import "./confirmation.css";
import { useHistory, useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { updateState } from "../../redux/payments";
import { cvv,  tokenize } from "../../query";
import { getMgpsApiHelperParams } from "../../helpers/mpgs-api-helper";
import TestOrProd from "../home/TestOrProd";
const axios = require('axios').default;

function Confirmation({location}: any) {

    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken;
    const groups = token?.claims.groups as string[];
    const isAdmin = groups && groups.some((g:string) => g === process.env.REACT_APP_ADMIN_ROLE);
    const paymentState = useAppSelector(state => state.payments);
    const {path} = getMgpsApiHelperParams(isAdmin, paymentState);
    let params = new URLSearchParams(location.search);
 
    const showToken = params.get(tokenize);
    
    const sessionId = paymentState.sessionId;
    const showSecurityResp = params.get(cvv);
    const { orderId } = useParams<{ orderId: string }>();
    const dispatch = useAppDispatch();

 
    let confirmationPath: string;

    const [error, setError] = useState(paymentState.paymentErrorMessage ? true: false);
    const [alertType, setAlertType] = useState("");
    const [alertIcon, setAlertIcon] = useState("");
    const [order, setOrder] = useState({});
    const [cardBrand, setCardBrand] = useState("");
    const [gatewayCode, setGatewayCode] = useState("");
    const [threedsResponse, set3DSResponse] = useState("");
    const [cvvResponse, setCVVResponse] = useState("");
    const [avsResponse, setAVSResponse] = useState("");
    const [gatewayToken, setGatewayToken] = useState("");

    const demoEnv = params.get("demoEnv");
        
    if (!demoEnv)
    {
        confirmationPath = path;
    }
    else{
        confirmationPath = demoEnv;
    }

    useEffect(() => {
        if (!orderId) {
            history.push("/");
        }
        
        if (demoEnv)
        {
            dispatch(updateState({ field: "environment", value: demoEnv }));
        }
    }, [demoEnv, dispatch, history, orderId]);

    useEffect(() => {
 
        dispatch(updateState({ field: "transactionId", value: undefined }));
        dispatch(updateState({ field: "orderId", value: undefined }));
        dispatch(updateState({ field: "redirectHtml", value: undefined }));

        const token = oktaAuth.getAccessToken();
        axios.get(`${process.env.REACT_APP_MPGS_BASE_URL}${confirmationPath}/get-order/${orderId}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                }
            }
        ).then((orderResp: any) => {

            if (orderResp.status === 200)
            {
                const transaction = orderResp.data?.transaction.length > 1 ? orderResp.data?.transaction.find((t: any) => t.transaction.id === "1") : orderResp.data.transaction[0];
                setOrder(orderResp.data);
                setAlertType(transaction?.response.gatewayCode?.replace(/_/g, " ")?.toLowerCase() === "approved" ? "success" : "danger");
                setAlertIcon(transaction?.response.gatewayCode?.replace(/_/g, " ")?.toLowerCase() === "approved" ? "fds-glyphs-checkmark-circle" : "fds-glyphs-warning2");
                setGatewayCode(transaction?.response.gatewayCode?.replace(/_/g, " ")?.toLowerCase());
                setCardBrand(transaction?.sourceOfFunds?.provided?.card?.brand?.toLowerCase());
                set3DSResponse(transaction?.transaction?.authenticationStatus?.replace(/_/g, " ")?.toLowerCase());
                setCVVResponse(transaction?.response?.cardSecurityCode?.gatewayCode?.replace(/_/g, " ")?.toLowerCase());
                setAVSResponse(transaction?.response?.cardholderVerification?.avs?.gatewayCode?.replace(/_/g, " ")?.toLowerCase());
                sessionStorage.clear();
                if (showToken && sessionId)
                {
                    const accessToken = oktaAuth.getAccessToken();
                    axios.post(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/create-token`,
                    {
                        sessionId: sessionId
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + accessToken,
                        }
                    },
            
                    ).then((tokenizeResp: any) => {
                        setGatewayToken(tokenizeResp.data.token);
                        setLoading(false);
                    });
                }
                else{
                    setLoading(false);
                }
            }
        }).catch(() => setError(true));
    }, [orderId, error, dispatch, oktaAuth, showToken, sessionId, path, confirmationPath]);

    return <div className="fds-pl level-three">
        <div className="fds-pl-jumbotron fds-pl-gray text-left">
            <Container className="fds-pl-jumbotron-utility">
                <Link to="/" className="fds-pl-back-button">
                    <i className="fds-glyphs-arrow-left4"></i>Return to Demo Selection
                </Link>
            </Container>
            <Container className="fds-pl-header-copy">
                <h1 className="type-heading-h1">ARC Pay API Demo</h1>
                <h2 className="type-heading-h2-subtitle">Transaction Complete</h2>
            </Container>
        </div>
        <Container className="fds-pl-container"> 
            <Row>
                <Col lg="12">     
                    <div  className="fds-pl-column p-3 ms-3 me-3 col">
                        <TestOrProd />
                        <Container style={{maxWidth: "600px", paddingTop: "50px"}}>        
                        <Row>
                            <Col lg="12">
                                <div>       
                                    {(loading || !order) && (
                                    <div style={{ height: "500px", width: "100%", lineHeight: "500px", textAlign: "center" }}>
                                        <div style={{ width: "300px", height: "100px", lineHeight: "initial", verticalAlign: "middle", display: "inline-block" }}>
                                            <Spinner animation="border" style={{ fontSize: "16px" }} role="status" variant="primary">
                                            </Spinner>
                                        </div>
                                    </div>
                                )}

                                    {!loading && order !== undefined && (
                                        <div>


                                          {!error && ( <Alert variant={alertType}>
                                                <div className="logo"><i className={alertIcon}></i></div>
                                                <div className="body"><div style={{textTransform: "capitalize", paddingLeft: "10px"}}>Transaction {gatewayCode}</div></div>
                                            </Alert>)}

                                            {error && ( <Alert variant="danger">
                                                <div className="logo"><i className="fds-glyphs-warning2"></i></div>
                                                <div className="body">Error! {paymentState.paymentErrorMessage}</div>
                                            </Alert>)}

                                            <Row><Col style={{paddingTop: "25px"}}  xl="6"><span className="header-label">Transaction Status:</span><div className="header-value" data-testid="gatewayCode">{gatewayCode}</div></Col><Col style={{paddingTop: "25px"}}  xl="6"><span className="header-label">CVV Response:</span><div className="header-value" data-testid="cvvResp">{showSecurityResp ? cvvResponse : "N/A"}</div></Col></Row>
                                            <Row><Col style={{paddingTop: "25px"}}  xl="6"><span className="header-label">Payment Type:</span><div className="header-value" data-testid="cardBrand">{cardBrand}</div></Col><Col style={{paddingTop: "25px"}}  xl="6"><span className="header-label">AVS Response:</span><div className="header-value" data-testid="avsResp">{showSecurityResp ? avsResponse : "N/A"}</div></Col></Row>
                                            <Row><Col style={{paddingTop: "25px"}}  xl="6"><span className="header-label">3DS Response:</span><div className="header-value" data-testid="threeDsResponse">{threedsResponse}</div></Col><Col style={{paddingTop: "25px"}}  className="header-label" xl="6">Gateway Token:<div className="header-value" data-testid="gatewayToken">{showToken ? gatewayToken : "N/A"}</div></Col></Row>

                                            <br />
                                            <br />
                                            <Row className="mb-3"><Col xl="12">
                                            <Form.Control as="textarea" rows={20} value={JSON.stringify(order, null, 4)} id="orderResp" data-testid="orderResp" className="shadow-none overflow-auto response-field" style={{ background: "none" }} readOnly  aria-label="order" />
                                            </Col></Row>
                                        </div>
                                    )}
                                </div>

                            </Col>
                        </Row>
                        </Container>
                    <div></div>
                </div>
                </Col>
                </Row>
            
                </Container>
            </div>
}

            export default Confirmation;