import { Button } from "react-bootstrap";
import {Fragment } from 'react'
import { useHistory } from "react-router-dom";

function CreatePaymentLinkButton()
{
    const history = useHistory();

    function handleClick() {
      history.push("/create-payment-link")
    }

    return  <Fragment><Button variant="primary"  onClick={handleClick}>Create Payment Link</Button>
  
    </Fragment>

}

export default CreatePaymentLinkButton;