import 'react-app-polyfill/ie11';
import { OnCancel, OnPaymentAuthorized, OnPaymentMethodSelected, OnValidateMerchant, PaymentRequest } from './types';
import { useApplePay } from '../../hooks/useApplePay';
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import { getMgpsApiHelperParams } from '../../helpers/mpgs-api-helper';
import { ApplePayButton } from './ApplePayButton';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';
import "./applepaybutton.css";
import { Fragment, memo, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../redux/hooks';
import { ErrorState, InputState } from '../session/FormState';
import ErrorModal from '../common/ErrorModal';

interface IApplePayInut {
  amount: any;
  values: InputState;
  validate: any;
  errors: ErrorState;
}

function ApplePay({ amount, values, validate, errors }: IApplePayInut) {

  const history = useHistory();
  const paymentState = useAppSelector(state => state.payments);
  const { authState, oktaAuth } = useOktaAuth();
  const token = authState?.accessToken;
  const groups = token?.claims.groups as string[];
  const [fieldErrors, setFieldErrors] = useState(false);
  const isAdmin = groups && groups.some((g: string) => g === process.env.REACT_APP_ADMIN_ROLE);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState<String>("");
  const { path } = getMgpsApiHelperParams(isAdmin, paymentState);
  const paymentRequest: PaymentRequest = {
    countryCode: "US",
    currencyCode: 'USD',
    supportedNetworks: ['visa', 'masterCard', 'amex'],
    merchantCapabilities: ['supports3DS'],
    total: {
      label: 'Total',
      amount: amount || "0.00",
    },
  };

  const onPaymentMethodSelected: OnPaymentMethodSelected = (event, session) => {

    const newLineItem = { label: "Airline Ticket", amount: amount.toString(), type: undefined };
    session.completePaymentMethodSelection({ newLineItems: undefined, newTotal: newLineItem });
  };

  const onPaymentAuthorized: OnPaymentAuthorized = (event, session) => {

    const orderId = uuidv4();
    const body = {} as any;


    //if the payment type is AMEX, need to add lineOfBusiness APPLE_PAY
    if (event.payment?.token?.paymentMethod?.network?.toUpperCase() === "AMEX") {
      body.lineOfBusiness = "APPLE_PAY";
    }

    //create a new session

    const sessionToken = oktaAuth.getAccessToken();
    axios.post(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/create-session`, body.lineOfBusiness ? body : undefined,
      {
        headers: {
          Authorization: "Bearer " + sessionToken,
        }
      }).then((createSession: any) => {

        //update the session with the source of funds and payment token details
        body.order = {};
        body.order.id = orderId;
        body.order.amount = amount;
        body.order.currency = "USD";
        body.order.reference = orderId;
        body.order.walletProvider = "APPLE_PAY";
        body.airline = {};
        body.airline.ticket = {};
        body.airline.ticket.issue = {
          "travelAgentCode": "49560744",
          "travelAgentName": "ARCTRAVELOFFICE"
        };


        if (values.address || values.city || values.state || values.zip || values.unit) {
          body.billing = {};
          body.billing.address = {};

          if (values.address) {
            body.billing.address.street = values.address;
          }

          if (values.city) {
            body.billing.address.city = values.city;
          }

          if (values.state) {
            body.billing.address.stateProvince = values.state;
          }

          if (values.zip) {
            body.billing.address.postcodeZip = values.zip;
          }

          if (values.unit) {
            body.billing.address.street2 = values.unit;
          }

        }

        if (values.firstName || values.lastName || values.middleName || values.namePrefix) {
          body.airline.passenger = [];
          const passenger: any = {};
          if (values.firstName) {
            passenger.firstName = values.firstName;
          }

          if (values.lastName) {
            passenger.lastName = values.lastName;
          }

          if (values.middleName) {
            passenger.middleName = values.middleName;
          }

          if (values.namePrefix) {
            passenger.title = values.namePrefix;
          }

          body.airline.passenger.push(passenger);
        }
        if (values.chargeDescription) {
          body.order.description = values.chargeDescription;
        }

        if (values.agencyInvoice) {
          body.order.invoiceNumber = values.agencyInvoice;
        }

        if (values.associatedAirlineTicket) {
          body.airline.ticket.ticketNumber = values.associatedAirlineTicket;
        }

        body.sourceOfFunds = {};
        body.sourceOfFunds.type = "CARD";
        body.sourceOfFunds.provided = {};
        body.sourceOfFunds.provided.card = {};
        body.sourceOfFunds.provided.card.devicePayment = {};
        // eslint-disable-next-line no-useless-escape
        body.sourceOfFunds.provided.card.devicePayment.paymentToken = `{\"data\":\"${event.payment.token.paymentData.data}\", \"signature\": \"${event.payment.token.paymentData.signature}\", \"header\":{\"publicKeyHash\":\"${event.payment.token.paymentData.header.publicKeyHash}\",\"ephemeralPublicKey\":\"${event.payment.token.paymentData.header.ephemeralPublicKey}\",\"transactionId\":\"${event.payment.token.paymentData.header.transactionId}\"},\"version\":\"${event.payment.token.paymentData.version}\"}`;
        
        const updateSessionToken = oktaAuth.getAccessToken();
        axios.put(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/update-session/${createSession.data.session.id}`, body,
          {
            headers: {
              Authorization: "Bearer " + updateSessionToken
            }
          }).then((updateSession: any) => {


            const paymentBody: any = {
              sessionId: createSession.data.session.id, orderId: orderId
            }

            const payToken = oktaAuth.getAccessToken();
            axios.put(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/pay`, paymentBody, {
              headers: {
                Authorization: "Bearer " + payToken
              }
            }).then((requestPayment: any) => {

              if (requestPayment.data.result === "SUCCESS") {
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
                setTimeout(() => history.push(`/confirmation/${orderId}`), 3000);
              }
              else {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                setTimeout(() => history.push(`/confirmation/${orderId}`), 3000);
              }
            }).catch((err: any) => {
              console.log("Error posting payment to gateway");
              session.completePayment(ApplePaySession.STATUS_FAILURE);
              setTimeout(() => history.push(`/confirmation/${orderId}`), 3000);
            });
          }).catch((err: any) => {
            console.log("Error updating session in gateway");
            session.completePayment(ApplePaySession.STATUS_FAILURE);
            setTimeout(() => history.push(`/confirmation/${orderId}`), 3000);
          });
      }).catch((err: any) => {
        console.log("Error creating session in gateway");
        session.completePayment(ApplePaySession.STATUS_FAILURE);
        setTimeout(() => history.push(`/confirmation/${orderId}`), 3000);
      });
  }

  const onValidateMerchant: OnValidateMerchant = (event, session) => {


    const body = {
      appleUrl: event.validationURL,
    }


    const validateToken = oktaAuth.getAccessToken();
    axios.post(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/applepay/validate-session`, body, {
      headers: {
        Authorization: "Bearer " + validateToken
      }
    }
    ).then((merchantSession: any) => {
      return session.completeMerchantValidation(merchantSession.data);
    }).catch((err: any) => {
      console.log("Error occurred validating the merchant");
      setErrorModalMessage("Error occurred validating the merchant. Please try another form of payment or try again later.")
      setShowErrorModal(true);
    });
  }

  const onCancel: OnCancel = (event, session) => {

  }

  const { applePayClickHandler } = useApplePay({
    paymentRequest,
    onPaymentAuthorized,
    onValidateMerchant,
    onCancel,
    onPaymentMethodSelected,
    values,
    validate,
    errors,
    dependencies: [amount],
  });

  useEffect(() => {

  }, [showErrorModal]);

  useEffect(() => {

    if (values.address && errors.address) {
      setFieldErrors(true);
      return;
    }

    if (values.city && errors.city) {
      setFieldErrors(true);
      return;
    }

    if (values.zip && errors.zip) {
      setFieldErrors(true);
      return;
    }

    if (values.state && errors.state) {
      setFieldErrors(true);
      return;
    }

    if (values.middleName && errors.middleName) {
      setFieldErrors(true);
      return;
    }

    if (values.firstName && errors.firstName) {
      setFieldErrors(true);
      return;
    }

    if (values.lastName && errors.lastName) {
      setFieldErrors(true);
      return;
    }

    if (values.namePrefix && errors.namePrefix) {
      setFieldErrors(true);
      return;
    }

    if (errors.orderAmount || errors.pnr || errors.unit || errors.agencyInvoice || errors.associatedAirlineTicket
      || errors.chargeDescription) {
      setFieldErrors(true);
      return;
    }

    setFieldErrors(false);
  }, [errors.address, errors.agencyInvoice, errors.associatedAirlineTicket, errors.chargeDescription, errors.city, errors.firstName, errors.lastName, errors.middleName, errors.namePrefix, errors.orderAmount, errors.pnr, errors.state, errors.unit, errors.zip, values.address, values.city, values.firstName, values.lastName, values.middleName, values.namePrefix, values.state, values.zip])

  return (
    <Fragment>
      <ErrorModal showErrorModal={showErrorModal} setShowErrorModal={setShowErrorModal} message={errorModalMessage} />
      {(parseFloat(amount) > 0.00 && fieldErrors === false && (window as any).ApplePaySession && ApplePaySession.canMakePayments()) &&
        <Fragment>
          <Row>
            <Col lg="12"><div>
              <ApplePayButton buttonstyle="black" onClick={applePayClickHandler} locale="en-US" type="check-out" />
              <br />
              <div><span>Or enter payment details below:</span></div>
              <br />
            </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div style={{ borderBottom: "1px solid rgba(232,232,232, 1)" }}></div>
            </Col>
          </Row>
          <br />
        </Fragment>
      }
    </Fragment>
  );
};

export default memo(ApplePay)