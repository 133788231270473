import React, { Fragment } from 'react';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaconfig }  from './config';

import Footer from './components/common/Footer';
import Home from './components/home/Home';

import Confirmation from './components/confirmation/Confirmation';
import Header from './components/common/Header';
import Login from './components/Login';
import HostedFullRedirectCheckout from './components/checkout/HostedFullRedirectCheckout';
import HostedCheckoutEmbed from './components/checkout/HostedCheckoutEmbed';
import HostedSession from './components/session/HostedSession';
import Pay from './components/pay/Pay';
import ThreeDSChallenge from './components/threeds-challenge/ThreeDSChallenge';
import PayResponse from './components/checkout/PayResponse';
import CreatePaymentLink from './components/payment-link/CreatePaymentLink';
import ScrollToTop from './components/common/ScrollToTop';




function AppWithRouterAccess() {
  const history = useHistory();

  const customAuthHandler = () => {
    history.push('/login');
  };

  const oktaAuth = new OktaAuth(oktaconfig.oidc);

 
  const restoreOriginalUri = async (oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path="/login" render={() => <Login config={oktaconfig.widget} />} />
          <Route path="/implicit/callback" render={() => <LoginCallback />} />
          <SecureRoute path='/threeds-challenge' component={ThreeDSChallenge} />
          <Fragment>
            <Header/>
              <div style={{ minHeight: "100vh" }}>
                <ScrollToTop />
                <SecureRoute path='/' exact={true} component={Home} />
                <SecureRoute path='/checkout-redirect' component={HostedFullRedirectCheckout} />
                <SecureRoute path='/checkout' component={HostedCheckoutEmbed} />
                <SecureRoute path='/session' component={HostedSession} />
                <SecureRoute path='/confirmation/:orderId' component={Confirmation} />
                <SecureRoute path='/pay' component={Pay} />
                <SecureRoute path='/pay-response' component={PayResponse} />
                <SecureRoute path='/create-payment-link' component={CreatePaymentLink} />
              </div>
            <Footer/>
          </Fragment>
        </Switch>
    </Security>
  );
}

export default AppWithRouterAccess;