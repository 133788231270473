import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "./store";

export interface IPaymentsState
{
    environment?: string;
    sessionId?: string;
    orderId?: string;
    transactionId?: string;
    redirectHtml?: string;
    needsAuth?: string;
    needsPayment?: string;
    paymentErrorMessage?: string;
}


const initialState: IPaymentsState = {
    environment: undefined,
    sessionId: undefined,
    orderId: undefined,
    transactionId: undefined,
    redirectHtml: undefined,
    needsAuth: undefined,
    needsPayment: undefined,
    paymentErrorMessage: undefined
  }

  export interface IPaymentAction  {
    field: string;
    value?: string;
  }

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
      updateState: (state: IPaymentsState, action: PayloadAction<IPaymentAction>) => {
        switch (action.payload.field)
        { 
          case "environment":
            state.environment = action.payload.value
            break;
          case "sessionId":
            state.sessionId = action.payload.value
            break;
          case "orderId":
            state.orderId = action.payload.value
            break;
          case "transactionId":
            state.transactionId = action.payload.value
            break;
          case "redirectHtml":
            state.redirectHtml = action.payload.value
            break;
          case "needsAuth":
            state.needsAuth = action.payload.value
            break;
          case "needsPayment":
            state.needsPayment = action.payload.value
            break;          
          case "paymentErrorMessage":
            state.paymentErrorMessage = action.payload.value
            break;

        }       
      },
    },
  });

  export const {  updateState } = paymentsSlice.actions
  

  export const selectPayments = (state: RootState) => state.payments;
  
  export default paymentsSlice.reducer
  