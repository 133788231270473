
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateState } from "../../redux/payments";

function TestOrProd() {
    const { authState } = useOktaAuth();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const paymentState = useAppSelector(state => state.payments);
    const [testProdValue, setTestProdValue] = useState(paymentState.environment?.toUpperCase() ?? "TEST");
    const [width, setWidth] = useState("192px");
 
    useEffect(() => {

        const handleResize = () => {
            if (window.innerWidth > 600) {
                setWidth("192px")
            } else {
                setWidth("100%")
            }
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {

        setIsDisabled(location?.pathname !== "/")
        const token = authState?.accessToken;
        const groups = token?.claims.groups as string[];
        setIsAdmin(groups && groups.some((g:string) => g === process.env.REACT_APP_ADMIN_ROLE))

    }, [authState?.accessToken, authState?.idToken?.claims, location]);

    useEffect(() => {
        setTestProdValue(paymentState.environment?.toUpperCase() ?? "TEST");     
        }, [paymentState.environment]);

    const handleChange = (event: any) => {

        if (event.target.value === "PROD") {
            dispatch(updateState({ field: "environment", value: "prod" }));

        } else {
            dispatch(updateState({ field: "environment", value: "test" }));
        }

        setTestProdValue(event.target.value);
    };

    return <> {isAdmin && <Row>
        <Col lg="12" >

            <div style={{ marginRight: "0", float: "right", width: width }}>
                <Form.Group className="mb-3">
                    <div className="select">
                        <Form.Control disabled={isDisabled} autoComplete="off" as="select" id="testProd" data-testid="testProd" value={testProdValue} onChange={handleChange} className="shadow-none" style={{ background: "none" }} aria-label="test or prod">
                            <option value="TEST">TEST</option>
                            <option value="PROD">PROD</option>
                        </Form.Control>
                    </div>

                </Form.Group>
            </div>
        </Col>
    </Row>
    }
    </>


}


export default TestOrProd;