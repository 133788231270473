import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWithRouterAccess from './AppWithRouterAccess';

import  { store, persistor }  from './redux/store'
import { Provider } from 'react-redux'
import RedirectComponent from './components/common/RedirectComponent';
import { PersistGate } from 'redux-persist/integration/react'

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <RedirectComponent />
        <AppWithRouterAccess/>
      </Router>
    </PersistGate>
  </Provider>
);
export default App;