import *  as FDS from "@arctravel/react-fds/lib"
import { useOktaAuth } from '@okta/okta-react';

function Footer()
{
    const { authState } = useOktaAuth();

    if (!authState || authState?.isAuthenticated === false)
    {
      return <div></div>
    }

    return <FDS.FDSFooter/>;

}

export default Footer;