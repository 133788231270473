import { Fragment } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import TestOrProd from '../home/TestOrProd';
import HostedFullRedirectCheckout from "./HostedFullRedirectCheckout";
import { v4 as uuidv4 } from 'uuid';

function HostedCheckoutEmbed() {
    return <Fragment>
    <div className="fds-pl level-two">
      <div className="fds-pl-jumbotron  fds-pl-primary text-left">
        <Container className="fds-pl-jumbotron-utility">
        </Container>
        <Container className="fds-pl-header-copy">
        <h1 className="type-heading-h1-on-dark">ARC Pay API Demo</h1>
                <h2 className="type-heading-h2-subtitle-on-dark">Hosted Checkout</h2>
        </Container>
      </div>
      <Container className="fds-pl-container first">
            <Row>
                <Col lg="12">
                    <div  className="fds-pl-column shadow p-3 ms-3 me-3 col">
                     <TestOrProd />
                      <HostedFullRedirectCheckout fromRoute={uuidv4()} embed={true} />
                    </div>
                    </Col>
                </Row>
            </Container>
            <div></div>
        </div>
    </Fragment>
}

export default HostedCheckoutEmbed;