import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { Container, Spinner } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import ErrorModal from "../common/ErrorModal";
import { getMgpsApiHelperParams } from "../../helpers/mpgs-api-helper";
import { buildQueryStringFromFeatureState } from "../../helpers/query-params-helper";
import { updateState } from "../../redux/payments";

export interface ICheckoutProps {
    embed: boolean;
    fromRoute: string
}

const HostedFullRedirectCheckout = ({ embed, fromRoute }: ICheckoutProps) => {

    const dispatch = useAppDispatch();
    const paymentState = useAppSelector(state => state.payments);
    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken;
    const groups = token?.claims.groups as string[];
    const isAdmin = groups && groups.some((g:string) => g === process.env.REACT_APP_ADMIN_ROLE);
    const {environment, path} = getMgpsApiHelperParams(isAdmin, paymentState);
    const MASTER_CARD_CHECKOUT_JS_SRC = `https://${environment}.arcpay.travel/static/checkout/checkout.js`;
    const featureState = useAppSelector(state => state.features);
    const [initializing, setInitializing] = useState(false);
    const [error, setError] = useState();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const paramsString =  buildQueryStringFromFeatureState(featureState);


    const MPGS_TIMEOUT = 5000;
    const onScriptLoad = ({ initialized, sessionId, order }: any) => {
    const { Checkout } = window as any;
    if (!Checkout) {
        return;
    }

    Checkout.configure({
        session: {
            id: sessionId,
        }
    });
    initialized();
};

    useEffect(() => {
        console.log("In use effect");
        

        const orderId = uuidv4();
        const loadCallbacks = () => {
            const prevScript = document.querySelector(`script[src="${MASTER_CARD_CHECKOUT_JS_SRC}"]`);
            
                if (prevScript) {
                    console.log("Removing existing script");
                    prevScript.remove();
                }
        
            const script = document.createElement("script");
            const code = `
                    function cancelCallback() {}
                    function errorCallback(error) {}
                    function completeCallback(resultIndicator, sessionVersion) {}
                `;
            script.type = "text/javascript";
            script.async = true;
            try {
                console.log("adding script");
                script.appendChild(document.createTextNode(code));
                document.body.appendChild(script);
            } catch (e) {
                script.text = code;
                document.body.appendChild(script);
            }
        };
        
        const loadScript = async (order: any,  sessionId: string)  => {
            if (!document) {
                return Promise.reject();
            }
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject();
                }, MPGS_TIMEOUT);
                const prevScript = document.querySelector(
                    `script[src="${MASTER_CARD_CHECKOUT_JS_SRC}"]`
                );
                if (prevScript) {
                    prevScript.remove();
                }
                loadCallbacks();
                const script = document.createElement("script") as any;
                script.src = MASTER_CARD_CHECKOUT_JS_SRC;
                script.async = true;
                script.onerror = reject;
                script["data-error"] = "errorCallback";
                script["data-cancel"] = "cancelCallback";
                script["data-complete"] = "completeCallback";
                //script["data-timeout"] = "timeoutCallback";
                script.onload = async () => {
                        await onScriptLoad({
                            initialized: resolve,
                            sessionId: sessionId,
                            order: order
                        });
        
                };
                document.body.appendChild(script);
            });
        };
            
        const token = oktaAuth.getAccessToken();
        setInitializing(true);
    
        const price = '200.00'
        const order = { id: orderId , amount: price, description: "TEST", reference: uuidv4(), currency: "USD" };

        const initiate_checkout = {enable3DS : featureState.include3DS ,checkoutMode: "WEBSITE", order : order, cancelUrl: process.env.REACT_APP_BASE_URL, returnUrl: `${process.env.REACT_APP_BASE_URL}confirmation/${orderId}${paramsString}`}
        
        axios.post(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/`, initiate_checkout,
        { headers: {
            Authorization: "Bearer " + token,
          } }
        ).then(async (res: any) => {

            dispatch(updateState({ field: "sessionId", value: res.data.session.id }));
        loadScript(order, res.data.session.id)
            .then(() => setInitializing(false)).then(() => pay(embed))
            .catch(() =>  setShowErrorModal(true));
        })
        .catch((reason) => {
            setShowErrorModal(true);
        });

    }, [oktaAuth, embed, fromRoute, featureState.include3DS, path, MASTER_CARD_CHECKOUT_JS_SRC, paramsString, dispatch]);
    (() => {
        (window as any).errorCallback = (err: any) => {
            console.log(JSON.stringify(err));
            if (err) setError(err);
        };
        (window as any).cancelCallback = () => {
            console.log("Payment cancelled");
        };
        (window as any).timeoutCallback = () => {
            console.log("Payment timeout");
        };
        (window as any).completeCallback = (resultIndicator: any, sessionVersion: any) => {
            console.log("checkout complete");
        };
    })();
    const pay = (embed: any) => {
        const { Checkout } = window as any;
        if (!Checkout) {
            return;
        }
        if (embed) {
            Checkout.showEmbeddedPage("#embed-target");
        }
        else {
            Checkout.showPaymentPage();
        }
    };
    return (
        <Container  style={{ paddingTop: "50px", minHeight: "900px !important" }}>
                  <ErrorModal showErrorModal={showErrorModal} setShowErrorModal={setShowErrorModal} />
  {initializing && embed && (
                                <div style={{ height: "500px", width: "100%", lineHeight: "500px", textAlign: "center" }}>
                                    <div style={{ width: "300px", height: "100px", lineHeight: "initial", verticalAlign: "middle", display: "inline-block" }}>
                                        <Spinner style={{fontSize: "16px"}} animation="border" role="status" variant="primary">
                                        </Spinner>
                                    </div>
                                </div>
                            )}
            <div id="embed-target" style={{ paddingTop: "50px", minHeight: "900px !important" }}> </div>
            {error && <div>Error: <ul>
                <li>Cause: {(error as any).cause}</li>
                <li>Explanation: {(error as any).explanation}</li>
            </ul>
            </div>
            }
        </Container>
    );
};

export default HostedFullRedirectCheckout;