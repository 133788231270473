import { useEffect, useState } from "react"
import { Row, Col, Spinner, Container, Form, Button, Toast, ToastContainer } from "react-bootstrap"
import *  as FDS from "@arctravel/react-fds/lib"
import { } from "@arctravel/react-fds/lib";
import { useHistory } from "react-router";
import { useAppSelector } from "../../redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import "./createpaymentlink.css"
import { IEmailRequest } from "./email-request";
import ErrorModal from "../common/ErrorModal";
import { getMgpsApiHelperParams } from "../../helpers/mpgs-api-helper";
import { cvv, threeDs, tokenize, wallet } from "../../query";
import TestOrProd from "../home/TestOrProd";



function CreatePaymentLink() {

    const history = useHistory();
    const featureState = useAppSelector(state => state.features);
    const paymentState = useAppSelector(state => state.payments);
    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken;
    const groups = token?.claims.groups as string[];
    const isAdmin = groups && groups.some((g:string) => g === process.env.REACT_APP_ADMIN_ROLE);
    const { path } = getMgpsApiHelperParams(isAdmin, paymentState);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [processing, setProcessing] = useState(false);
    const price = "9.99";
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const date = new Date(year, month + 2, 0);
    const invoiceDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();
    const SEND_AS_EMAIL = "donotreply@arc.travel";
    const MESSAGE_BODY = `ARCTIC Travel: 45-0000000\nInvoice ID: 1\nIssue Date: ${invoiceDate}\nClient: Jane Doe\nP.O. Number:\nAmount: $${price}\nDue: ${invoiceDate} (upon receipt)\n\nThank you!`;

    const params = [];
    
    if (featureState.include3DS)
    {
      params.push(`${threeDs}=true`);
    }

    if (featureState.includeWalletPayments)
    {
      params.push(`${wallet}=true`);
    }
    if (featureState.includeAVSandCVVResponses)
    {
      params.push(`${cvv}=true`);
    }

    if (featureState.includeTokenization)
    {
      params.push(`${tokenize}=true`);
    }

    params.push(`demoEnv=${path}`);

    const paramsString = params.length > 0 ? "?" + params.join("&") : "";

    interface ICreatePaymentLinkForm {
        recipient?: string;
        messageSubject: string;
        messageBody: string;
    }

    interface ICreatePaymentLinkFormErrors {
        recipient?: string;
        messageSubject?: string;
        messageBody?: string;
    }

    const [values, setValues] = useState<ICreatePaymentLinkForm>({
        recipient: "",
        messageSubject: "INVOICE 001 From ARCTIC Travel Agent",
        messageBody: MESSAGE_BODY
    });

    const handleChange = (e: any) => {
        let name = e.target.id;
        let value = e.target.value;

        setValues(prev => { return { ...prev, [name]: value } });

        validate(e, name, value);
    }


    const [errors, setErrors] = useState<ICreatePaymentLinkFormErrors>({

    });

    useEffect(() => {
        if (submitClicked && !errors.messageSubject && !errors.messageBody && !errors.recipient) {
   
            window.scrollTo(0, 0);
            setProcessing(true);
            const order = { id: uuidv4(), amount: price, description: "TEST", reference: uuidv4(), currency: "USD" };
            const initiate_checkout = { enable3DS: featureState.include3DS, checkoutMode: "PAYMENT_LINK", order: order, cancelUrl: process.env.REACT_APP_BASE_URL, returnUrl: `${process.env.REACT_APP_BASE_URL}confirmation/${order.id}${paramsString}` };

            const token = oktaAuth.getAccessToken();
            axios.post(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/`, initiate_checkout,
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }).then(async (res: any) => {

                    const emailRequest: IEmailRequest = {
                        recipient: values.recipient!,
                        sender: SEND_AS_EMAIL,
                        messageBody: values.messageBody, messageSubject: values.messageSubject, paymentLink: res.data.paymentLink.url
                    };

       
                    const accessToken = oktaAuth.getAccessToken();
                    axios.post(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/send-email`, emailRequest,
                        {
                            headers: {
                                Authorization: "Bearer " + accessToken
                            }
                        }).then((emailResp: any) => {

                            if (emailResp.status === 201) {
                                setProcessing(false);
                                setShowToast(true);
                            }
                            else {
                                setProcessing(false);
                                setShowErrorModal(true);
                            }
                        }).catch((ex) => {
                            setProcessing(false);
                            setShowErrorModal(true);
                        });
                }).catch((ex) => {
                    setProcessing(false);
                    setShowErrorModal(true);
                });
        }

        if (submitClicked)
        {
            setSubmitClicked(false);
        }
     }, [oktaAuth, errors, featureState.include3DS, submitClicked, values.messageBody, values.messageSubject, values.recipient, paramsString, path]);


    const send = () => {

        setSubmitClicked(true);
        validate({}, "recipient", values.recipient);
        validate({}, "messageBody", values.messageBody);
        validate({}, "messageSubject", values.messageSubject);
    }


    const cancel = () => {
        history.push("/");
    }

    const validate = (event: any, name: any, value: any) => {

        switch (name) {
            case 'recipient':
                if (/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i.test(value)) {
                    if (errors.recipient) {
                        setErrors(prev => {
                            return { ...prev, recipient: undefined }
                        })
                    }
                } else if (value?.trim()?.length === 0 || !value) {
                    setErrors(prev => {
                        return { ...prev, recipient: "Recipient is required." }
                    })
                }
                else {
                
                    setErrors(prev => {
                        return { ...prev, recipient: "Recipient must be a valid email address." }
                    })
                }
                break;
            case 'messageBody':
                if (value?.trim()?.length === 0 || !value) {
                    setErrors(prev => {
                        return { ...prev, messageBody: name + "is required." }
                    })
                }
                else {

                    if (errors.messageBody) {
                        setErrors(prev => {
                            return { ...prev, messageBody: undefined }
                        });
                    }
                }
                break;
            case 'messageSubject':
                if (value?.trim()?.length === 0 || !value) {
                    setErrors(prev => {
                        return { ...prev, messageSubject: name + "is required." }
                    })
                }
                else {

                    if (errors.messageSubject) {
                        setErrors(prev => {
                            return { ...prev, messageSubject: undefined }
                        })
                    }
                }

                break;
        }
    }

    return <FDS.FDSLevel mainTitle="ARC Pay API Demo" type="two" subTitle="Create Payment Link">

        <FDS.FDSLevel.Centered>
            <TestOrProd />
            <Container style={{ maxWidth: "600px" }}>
                <ToastContainer className="p-3" position="top-end">
                    <Toast className="success"
                        show={showToast}
                        onClose={() => setShowToast(false)}
                    >
                        <Toast.Header>
                            <strong className="mr-auto"><i className="fds-glyphs-checkmark3"></i> Success</strong>
                        </Toast.Header>
                        <Toast.Body>Payment Link email sent.</Toast.Body>
                    </Toast>
                </ToastContainer>
                <ErrorModal showErrorModal={showErrorModal} setShowErrorModal={setShowErrorModal} />

                {processing && (
                    <div style={{ height: "500px", width: "100%", lineHeight: "500px", textAlign: "center" }}>
                        <div style={{ width: "300px", height: "100px", lineHeight: "initial", verticalAlign: "middle", display: "inline-block" }}>
                            <Spinner style={{ fontSize: "16px" }} animation="border" role="status" variant="primary">
                            </Spinner>
                        </div>
                    </div>
                )}
                {!processing && (<Form>
                    <br />
                    <br />
                    <span className="createPaymentLink-subheader">Please Enter Your Information</span>
                    <br />
                    <br />

                    <Row>
                        <Col lg="12">

                            <Form.Group className="mb-4">
                                <div><span className="sendAs-label">Send As </span></div>
                                <div><span style={{ color: "#0060F0" }}>{SEND_AS_EMAIL}</span></div>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label htmlFor="recipient">Recipient </Form.Label>
                                <Form.Control type="email" autoComplete="off" value={values.recipient} id="recipient" onChange={handleChange} isInvalid={errors.recipient ? true : false} aria-label="enter recipient" />
                                <Form.Control.Feedback type="invalid">{errors.recipient}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label htmlFor="messageSubject">Message Subject </Form.Label>
                                <Form.Control type="text" value={values.messageSubject} disabled id="messageSubject" onChange={handleChange} isInvalid={errors.messageSubject ? true : false}  aria-label="enter message subject" />
                                <Form.Control.Feedback type="invalid">{errors.messageSubject}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label htmlFor="messageBody">Message Body</Form.Label>
                                <Form.Control autoComplete="off" as="textarea" value={values.messageBody} disabled rows={10} id="messageBody" style={{ height: "200px" }} onChange={handleChange} isInvalid={(errors.messageBody ? true : false)} aria-label="enter message body" />
                                <Form.Control.Feedback type="invalid">{errors.messageBody}</Form.Control.Feedback>
                            </Form.Group>

                            <br />

                            <Row>

                                <Col lg="12" style={{ textAlign: "right", marginRight: "0px" }}>
                                <div style={{display: "inline-block", paddingBottom: "20px"}}>
                                        <Button variant="secondary"  onClick={cancel}>
                                            Cancel
                                        </Button>
                                        </div>   
                                        <div style={{display: "inline-block"}}>
                                        <Button variant="primary" style={{marginLeft: "20px"}}  onClick={send} >
                                            Send Invoice
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>)}
            </Container>
        </FDS.FDSLevel.Centered>
        <div></div>
    </FDS.FDSLevel>
}

export default CreatePaymentLink
