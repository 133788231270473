import { Form } from "react-bootstrap";
import { INCLUDE_AVS_AND_CVV_RESPONSES, updateState } from "../../redux/features";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

function IncludeAddressValidationCheckbox (){

    const dispatch = useAppDispatch();
    const featureState = useAppSelector(state => state.features);

    function handleClick() { 
          dispatch(updateState({feature: INCLUDE_AVS_AND_CVV_RESPONSES, value: featureState.includeAVSandCVVResponses ? false : true }));
      }

    return <Form.Check type="checkbox" id="avs" label="Include AVS / CVV Response" checked={featureState.includeAVSandCVVResponses} onChange={handleClick}/>

}

export default IncludeAddressValidationCheckbox;