import { Fragment, useEffect } from 'react';
import { useAppSelector } from '../../redux/hooks';


export interface IChallengeProps {
    challenge: string
}

function ThreeDSChallenge() {

    const paymentState = useAppSelector(state => state.payments);

    useEffect(() => {

        var doc = document.createElement('div');
        doc.id = "threedcontainer";
        doc.innerHTML = paymentState.redirectHtml!;
        document.body.appendChild(doc);

        const scriptElement = document.createElement('script')
        scriptElement.type = 'text/javascript'
        scriptElement.async = true
        scriptElement.innerHTML = "eval(document.getElementById('authenticate-payer-script').text)";
        document.body.appendChild(scriptElement);

    }, [paymentState.redirectHtml])

    return (<Fragment><div>

    </div>
    </Fragment>)


}


export default ThreeDSChallenge;