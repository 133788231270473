/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Navbar, Container, Image, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import logo from '../../arc-logo-l-teal.png'


function Header() {

    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState<any>(null);

  
    useEffect(() => {

      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo(null);
      } else {
        oktaAuth.getUser().then((info: any) => {
          setUserInfo(info);
        });
      }
    }, [authState, oktaAuth]); 


    const signOut = () => {
        oktaAuth.signOut({postLogoutRedirectUri: process.env.REACT_APP_OKTA_SIGNOUT_REDIRECT_URL});
      };
    
      if (!authState || authState?.isAuthenticated === false)
      {
        return <div></div>
      }
    
    return (<Navbar expand="sm">
        <Container style={{maxWidth: "100%"}}>
            <Navbar.Brand as={Link} className="no-style" style={{color: "black"}} to="/" ><Image
                className="d-inline-block align-top"
                alt="Airlines Reporting Corporation Logo"
                height="32"
                src={logo}></Image> ARC Pay</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav className="ml-auto">

                </Nav>
                <div className="navbar-utility">
           
                    <a
                        href="#"
                        className="navbar-utility-link navbar-utility-username"
                    >
                        {userInfo?.preferred_username}
                    </a>

     
                    <a href="#" className="navbar-utility-link" onClick={signOut}>
                        Sign Out
                    </a>
                </div>
            </Navbar.Collapse>
        </Container>
    </Navbar>)
}

export default Header