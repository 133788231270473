import { Form } from "react-bootstrap";
import { INCLUDE_TOKENIZATION, updateState } from "../../redux/features";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

function IncludeTokenizationCheckbox (){

    const dispatch = useAppDispatch();
    const featureState = useAppSelector(state => state.features);

    function handleClick() { 
        dispatch(updateState({feature: INCLUDE_TOKENIZATION, value: featureState.includeTokenization ? false : true }));
  
      }

    return <Form.Check type="checkbox" id="tokenization" label="Include Tokenization" checked={featureState.includeTokenization} onChange={handleClick}/>

}

export default IncludeTokenizationCheckbox;