import React from 'react';
import { Redirect } from 'react-router-dom';
import OktaSignInWidget from './OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';



const Login = ({ config }: any) => {
  const { oktaAuth, authState } = useOktaAuth();
 
  const onSuccess = (tokens: any) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err:any) => {
    console.log('error logging in', err);
  };

  if (!authState) 
   {
     return null;
   }


  return authState.isAuthenticated ?
  <Redirect to={{ pathname: '/' }}/>  :
    <OktaSignInWidget
      config={config}
      onSuccess={onSuccess}
      onError={onError}/>;
};
export default Login;