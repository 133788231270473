import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "./store"


export const INCLUDE_3DS = "include3DS";

export const INCLUDE_TOKENIZATION = "includeTokenization";

export const INCLUDE_WALLET_PAYMENTS = "includeWalletPayments";

export const INCLUDE_AVS_AND_CVV_RESPONSES = "includeAVSandCVVResponses";

export interface IFeaturesState
{
    include3DS: boolean;
    includeTokenization: boolean;
    includeWalletPayments: boolean;
    includeAVSandCVVResponses: boolean;
}

const initialState: IFeaturesState = {
    include3DS: false,
    includeTokenization: false,
    includeAVSandCVVResponses: false,
    includeWalletPayments: false
  }

  export interface IFeatureToggle  {
    feature: string;
    value: boolean;
  }


export const featuresSlice = createSlice({
    name: 'features',
    initialState,
    reducers: {
      updateState: (state: IFeaturesState, action: PayloadAction<IFeatureToggle>) => {
        switch(action.payload.feature){

          case "include3DS":
            state.include3DS = action.payload.value
            break;
          case "includeAVSandCVVResponses":
            state.includeAVSandCVVResponses = action.payload.value
            break;
          case "includeTokenization":
            state.includeTokenization = action.payload.value
            break;
          case "includeWalletPayments":
            state.includeWalletPayments = action.payload.value
            break;
        }
      },
    },
  })
  
  export const {  updateState } = featuresSlice.actions
  
  // Other code such as selectors can use the imported `RootState` type
  export const selectFeatures = (state: RootState) => state.features;
  
  export default featuresSlice.reducer