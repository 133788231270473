import { cvv, threeDs, tokenize, wallet } from "../query";

export function buildQueryStringFromFeatureState(featureState: any)
{
    const params = [];
    
    if (featureState.include3DS)
    {
      params.push(`${threeDs}=true`);
    }

    if (featureState.includeWalletPayments)
    {
      params.push(`${wallet}=true`);
    }
    if (featureState.includeAVSandCVVResponses)
    {
      params.push(`${cvv}=true`);
    }

    if (featureState.includeTokenization)
    {
      params.push(`${tokenize}=true`);
    }

    return params.length > 0 ? "?" + params.join("&") : "";
}